.widget-row {
	margin-top: $grid-gutter-width/3;
	margin-bottom: $grid-gutter-width;
}

.widget-panel {
	box-shadow: 0 6px 10px rgba(0,0,0,0.08), 0 0 3px rgba(0,0,0,0.15);
	padding: $widget-spacing;
	margin: -$widget-spacing;
	min-height: 317px - ($grid-gutter-width/4);

	.widget-graph-wrapper {
		margin: -$widget-spacing;
	}

	.wrapper-table-width {
		margin: 0 (-$widget-spacing);
	}
	.table {
		margin-bottom: 0;
		tr {
			&:first-child td {
				border: none;
			}
			td {
				text-align: right;
				font-size: 0.87rem;
				line-height: 1;
				padding: 1rem 0.3rem;
				border-color: #bfc3c5;
				white-space: nowrap;
			}
			td:first-child {
				padding-left: $widget-spacing;
				text-align: left;
				font-size: 0.94rem;
				color: $table-grey-color;
			}
			td:last-child {
				padding-right: $widget-spacing;
			}
		}
	}
}



.welcome-widget {
	position: relative;
	height: 130px;

	h1 {
		font-weight: 300;
		text-align: center;
		padding-top: 40px;
		margin-right: auto;
		margin-left: auto;
	}

	&:before, &:after {
		display: block;
		content: "";
		position: absolute;
		z-index: 2;
	}

	&:before {
		background: 50% 50% url('/build/images/lamp.svg') no-repeat;
		width: 144px; height: 131px;
		left: 10%; bottom: 0;
	}

	&:after {
		background: 50% 50% url('/build/images/desktop.svg') no-repeat;
		width: 196px; height: 140px;
		right: 7%; bottom: 0;
	}

	hr {
		position: absolute;
		bottom: 0; left: 0; right: 0;
		height: $grid-gutter-width/2;
		border-top: 1px solid $ice_blue;
		border-left: 1px solid $ice_blue;
		border-right: 1px solid $ice_blue;
		z-index: 1;
		margin: 0 -$widget-spacing; padding: 0;
	}
}
.payout-widget {
	position: relative;
	.previous-payout {
		opacity: 0.3;
		p {
			font-size: 1.45rem;
			margin: 0;
		}
	}
	.current-payout {
		margin-top: 15px;
		p {
			margin: 0;
		}
		.current-amount {
			display: block;
			font-size: 2.4rem;
			line-height: 1.2;
		}
	}
	.next-payout {
		position: absolute;
		background-color: $ice_blue;
		padding: $grid-gutter-width/5 $widget-spacing;
		bottom: 0;
		left: 0;
		right: 0;
		p {
			margin: 0;
		}
		.next-date {
			span {
				display: block;
				margin: 7px 0;
				font-size: 1.27rem;
				line-height: 1;
			}
		}
		.final-amount {
			opacity: 0.2;
			span {
				display: block;
				margin: 4px 0;
				font-size: 1.6rem;
				line-height: 1;
			}
		}
	}
}
.quickstart-widget {
	padding-bottom: $grid-gutter-width/5;
	p {
		margin: 0;
	}
	.artwork {
		margin: -10px 0;
	}
}
.wrapper-progress {
	min-width: 105px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	.progress {
		width: 78%;
	}
}
.account-summary-widget {
	position: relative;
	.current-balance {
		margin-top: 15px;
		p {
			margin: 0;
		}
		.current-balance-amount {
			display: block;
			font-size: 3.4rem;
			line-height: 1.2;
		}
	}
	.subscription-plan {
		position: absolute;
		background-color: $ice_blue;
		padding: $grid-gutter-width/5 $widget-spacing;
		bottom: 0;
		left: 0;
		right: 0;

		h3 {
			font-size: 1.45rem;
			margin-bottom: 0.8rem;
		}
		p {

		}

		a {
			color: $aqua_marine;
		}

		.subscription-plan-name {
			margin: 7px 0;
			line-height: 1;
			small {
				font-size: 0.8rem;
			}
		}
		.subscription-plan-expire-at {
			&.expired {
				color: $terra_cotta
			}
			margin: 7px 0;
			small {
				font-size: 0.8rem;
			}
		}
	}
}