.modal-dialog-side {
	display: flex;
	align-items: center;
	min-height: calc(100% - #{$modal-dialog-margin * 2});

	// Ensure `modal-dialog-centered` extends the full height of the view (IE10/11)
	&::before {
		display: block; // IE10
		height: calc(100vh) - #{$modal-dialog-margin * 2};
		content: "";
	}

	// Ensure `.modal-body` shows scrollbar (IE10/11)
	&.modal-dialog-scrollable {
		flex-direction: column;
		justify-content: center;
		height: 100%;

		.modal-content {
			max-height: none;
		}

		&::before {
			content: none;
		}
	}

	.modal-content {
		@include shadow-panel();

		background: rgba(255,255,255,1);

		padding-left: $sidebar-width;

		.modal-header, .modal-body, .modal-footer {
			padding-left: $grid-gutter-width;
			padding-right: $grid-gutter-width;
		}
		.modal-footer {
			padding-bottom: $grid-gutter-width/2;

			.form-group {
				margin-bottom: 0;
			}
		}
	}

}

$modal-dialog-side-large-width: 1200px;
$modal-dialog-side-big-width: 900px;
$modal-dialog-side-small-width: 450px;

@include media-breakpoint-up(sm) {
	// Automatically set modal's width for larger viewports
	.modal-dialog-side {
		margin: 0;
	}
	.modal-dialog-side-large {
		max-width: $modal-dialog-side-large-width + $sidebar-width;
	}
	.modal-dialog-side-big {
		max-width: $modal-dialog-side-big-width + $sidebar-width;
	}
	.modal-dialog-side-small {
		max-width: $modal-dialog-side-small-width + $sidebar-width;
	}
}

.modal-dialog-side-large {
	.modal.fade & {
		@include transition(all 0.5s ease);
		transform: translate(-($modal-dialog-side-large-width + $sidebar-width), 0);
	}
	.modal.show & {
		transform: $modal-show-transform;
	}
}

.modal-dialog-side-big {
	.modal.fade & {
		@include transition(all 0.5s ease);
		transform: translate(-($modal-dialog-side-big-width + $sidebar-width), 0);
	}
	.modal.show & {
		transform: $modal-show-transform;
	}
}

.modal-dialog-side-small {
	.modal.fade & {
		@include transition(all 0.5s ease);
		transform: translate(-($modal-dialog-side-small-width + $sidebar-width), 0);
	}
	.modal.show & {
		transform: $modal-show-transform;
	}
}

.modal-backdrop {
	background: rgba(255,255,255,0.5);

	// Fade for backdrop
	&.fade {
		opacity: 1;
	}
	&.show {
		opacity: 1;
	}
}

.modal-dialog-inline {
	margin: 0;
	@include transition(opacity 0.4s ease-in);
	animation-duration: 0.3s;
	.modal-content {
		@include shadow-panel();
		padding: 0;
		border-radius: 27px;
		.inline-modal-inner-wrapper {
			display: block;
			padding: $grid-gutter-width/6;
			white-space: nowrap;

			label:first-child {
				padding-left: $grid-gutter-width/6;
			}
		}
	}
}

.restricted-frame {
	background: $terra_cotta;
	color: white;
	a {
		color: white;
	}
	.btn-outline-secondary {
		border-color: white;
		color: white;
	}
}

.modal-subtitle {
	line-height: 2.7;
}


// big white tooltip

$big-tooltip-font-size:                 $tooltip-font-size;
$big-tooltip-max-width:                 250px;
$big-tooltip-color:                     #000;
$big-tooltip-bg:                        white;
$big-tooltip-border-radius:             5px;
$big-tooltip-opacity:                   $tooltip-opacity;
$big-tooltip-padding-y:                 $tooltip-padding-y;
$big-tooltip-padding-x:                 $tooltip-padding-x;
$big-tooltip-margin:                    $tooltip-margin;

$big-tooltip-arrow-width:               20px;
$big-tooltip-arrow-height:              15px;
$big-tooltip-arrow-color:               $big-tooltip-bg;

.tooltip.tooltip-white {
	margin: $big-tooltip-margin;
	// Our parent element can be arbitrary since tooltips are by default inserted as a sibling of their target element.
	// So reset our font and text properties to avoid inheriting weird values.

	@include font-size($big-tooltip-font-size);

	.big-arrow {
		width: $big-tooltip-arrow-width;
		height: $big-tooltip-arrow-height;
	}

	&.bs-tooltip-top {
		padding: $big-tooltip-arrow-height 0;

		.big-arrow {
			bottom: 0;

			&::before {
				top: 0;
				border-width: $big-tooltip-arrow-height ($big-tooltip-arrow-width / 2) 0;
				border-top-color: $big-tooltip-arrow-color;
			}
		}
	}

	&.bs-tooltip-right {
		padding: 0 $big-tooltip-arrow-height;

		.big-arrow {
			left: 0;
			width: $big-tooltip-arrow-height;
			height: $big-tooltip-arrow-width;

			&::before {
				right: 0;
				border-width: ($big-tooltip-arrow-width / 2) $big-tooltip-arrow-height ($big-tooltip-arrow-width / 2) 0;
				border-right-color: $big-tooltip-arrow-color;
			}
		}
	}

	&.bs-tooltip-bottom {
		padding: $big-tooltip-arrow-height 0;

		.big-arrow {
			top: 0;

			&::before {
				bottom: 0;
				border-width: 0 ($big-tooltip-arrow-width / 2) $big-tooltip-arrow-height;
				border-bottom-color: $big-tooltip-arrow-color;
			}
		}
	}

	&.bs-tooltip-left {
		padding: 0 $big-tooltip-arrow-height;

		.big-arrow {
			right: 0;
			width: $big-tooltip-arrow-height;
			height: $big-tooltip-arrow-width;

			&::before {
				left: 0;
				border-width: ($big-tooltip-arrow-width / 2) 0 ($big-tooltip-arrow-width / 2) $big-tooltip-arrow-height;
				border-left-color: $big-tooltip-arrow-color;
			}
		}
	}
}

.bs-tooltip-auto {
	&[x-placement^="top"] {
		@extend .bs-tooltip-top;
	}
	&[x-placement^="right"] {
		@extend .bs-tooltip-right;
	}
	&[x-placement^="bottom"] {
		@extend .bs-tooltip-bottom;
	}
	&[x-placement^="left"] {
		@extend .bs-tooltip-left;
	}
}

// Wrapper for the tooltip content
.big-tooltip-inner {
	max-width: $big-tooltip-max-width;
	padding: $big-tooltip-padding-y $big-tooltip-padding-x;
	color: $big-tooltip-color;
	text-align: center;
	background-color: $big-tooltip-bg;
	@include border-radius($big-tooltip-border-radius);

	@include shadow-panel();
}


.tooltip-calendar-event-group {
	text-align: left;

	&+.tooltip-calendar-event-group {
		margin-top: 0.5rem;
	}
	.tooltip-calendar-event-item {
		.note {
			opacity: 0.5;
		}
	}
}



.tooltip-timeline-payments {
	font-size: 11px;
	line-height: 11px;
	font-weight: 300;

	tr.contract {
		opacity: 0.3;
	}

	td {
		text-align: left;
		padding: 2px 3px;
		max-width: 80px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	td:first-child {
		text-align: right;
	}
	td:last-child {
		color: $beige_gray;
	}

	span {
		font-weight: 400;
		&[data-type="penalty"] {
			color: $terra_cotta;
		}
		&[data-type="salary"] {
			color: $aqua_marine;
		}
		&[data-type="bonus"] {
			color: $turtle_green;
		}
	}
}

.modal-content {

}