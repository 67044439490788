$datepicker-hover-color: $ice_blue;
$datepicker-range-color: $aqua_marine;
$datepicker-active-color: $aqua_marine;
$datepicker-focus-color: $turtle_green;

@mixin calenday-day( $background-color, $border-color ) {
  @include button-variant($background-color, $border-color,
          $background-color, $background-color, $background-color, $background-color);
  @include box-shadow(none);
}

.datepicker {
  border-radius: 27px;
  &-inline {
    width: 220px;
  }
  direction: ltr;
  &-rtl {
    direction: rtl;
    &.dropdown-menu { left: auto; }
    table tr td span {
      float: right;
    }
  }
  &-dropdown {
    top: 0;
    left: 0;
    padding: 14px;
    border: 0;
    box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.08);
    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      border: 0;
      background-image: url("data:image/svg+xml,%3Csvg width='32px' height='16px' viewBox='0 0 32 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpolygon id='path-1' points='16 10 8 16 24 16'%3E%3C/polygon%3E%3Cfilter x='-140.6%25' y='-375.0%25' width='381.2%25' height='850.0%25' filterUnits='objectBoundingBox' id='filter-2'%3E%3CfeOffset dx='0' dy='0' in='SourceAlpha' result='shadowOffsetOuter1'%3E%3C/feOffset%3E%3CfeGaussianBlur stdDeviation='7.5' in='shadowOffsetOuter1' result='shadowBlurOuter1'%3E%3C/feGaussianBlur%3E%3CfeColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' type='matrix' in='shadowBlurOuter1'%3E%3C/feColorMatrix%3E%3C/filter%3E%3C/defs%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cuse fill='black' fill-opacity='1' filter='url(%23filter-2)' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill='%23FFFFFF' fill-rule='evenodd' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/svg%3E");
      width: 32px;
      height: 16px;
      pointer-events: none;
    }
    &:after {
      content: '';
      display: none;
    }

    &.datepicker-orient-left:before   { left: 14px; }

    &.datepicker-orient-right:before  { right: 14px; }

    &.datepicker-orient-bottom:before { top: -14px; }

    &.datepicker-orient-top:before {
      bottom: -14px;
      background-image: url("data:image/svg+xml,%3Csvg width='32px' height='16px' viewBox='0 0 32 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpolygon id='path-1' points='16 6 8 0 24 0'%3E%3C/polygon%3E%3Cfilter x='-140.6%25' y='-375.0%25' width='381.2%25' height='850.0%25' filterUnits='objectBoundingBox' id='filter-2'%3E%3CfeOffset dx='0' dy='0' in='SourceAlpha' result='shadowOffsetOuter1'%3E%3C/feOffset%3E%3CfeGaussianBlur stdDeviation='7.5' in='shadowOffsetOuter1' result='shadowBlurOuter1'%3E%3C/feGaussianBlur%3E%3CfeColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' type='matrix' in='shadowBlurOuter1'%3E%3C/feColorMatrix%3E%3C/filter%3E%3C/defs%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cuse fill='black' fill-opacity='1' filter='url(%23filter-2)' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill='%23FFFFFF' fill-rule='evenodd' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3C/svg%3E");
    }
  }
  table {
    margin: 0;
    user-select: none;
    tr {
      td, th {
        text-align: center;
        width: 30px;
        height: 30px;
        border-radius: 15px;
        border: none;
      }
    }
  }
  // Inline display inside a table presents some problems with
  // border and background colors.
  .table-striped & table tr {
    td, th {
      background-color: transparent;
    }
  }

  table tr th {
    font-weight: $font-weight-normal;
    color: black;
    &.dow {
      color: $aqua_marine;
    }
  }
  table tr td {
    &.old,
    &.new {
      color: $btn-link-disabled-color;
    }
    &.day:hover,
    &.focused {
      background: $datepicker-hover-color;
      cursor: pointer;
    }
    &.disabled,
    &.disabled:hover {
      background: none;
      color: $btn-link-disabled-color;
      cursor: default;
    }
    &.highlighted {
      $highlighted-bg: $datepicker-active-color;
      @include calenday-day($highlighted-bg, $highlighted-bg);
      border-radius: 0;

      &.focused {
        background: $datepicker-focus-color;
      }

      &.disabled,
      &.disabled:active {
        background: $highlighted-bg;
        color: $btn-link-disabled-color;
      }
    }
    &.today {
      $today-bg: lighten(orange, 30%);
      @include calenday-day($today-bg, $today-bg);

      &.focused {
        background: darken($today-bg, 10%);
      }

      &.disabled,
      &.disabled:active {
        background: $today-bg;
        color: $btn-link-disabled-color;
      }
    }
    &.range-start {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &.range-end {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    &.range {
      border-radius: 0;
    }

    &.range-start,
    &.range-end,
    &.range {
      $range-bg: $datepicker-hover-color;
      @include calenday-day($range-bg, $range-bg);

      &.focused {
        background: $datepicker-hover-color;
      }

      &.disabled,
      &.disabled:active {
        background: $range-bg;
        color: $btn-link-disabled-color;
      }
    }
    &.range.highlighted {
      $range-highlighted-bg: $datepicker-active-color;
      @include calenday-day($range-highlighted-bg, $range-highlighted-bg);

      &.focused {
        background: $datepicker-focus-color;
      }

      &.disabled,
      &.disabled:active {
        background: $range-highlighted-bg;
        color: $btn-link-disabled-color;
      }
    }
    &.range.today {
      $range-today-bg: $datepicker-active-color;
      @include button-variant($range-today-bg, $range-today-bg);

      &.disabled,
      &.disabled:active {
        background: $range-today-bg;
        color: $btn-link-disabled-color;
      }
    }
    &.selected,
    &.selected.highlighted {
      @include button-variant($datepicker-active-color, $datepicker-active-color);
    }
    &.active,
    &.active.highlighted {
      @include button-variant($datepicker-active-color, $datepicker-active-color);
    }
    span {
      display: block;
      width: 23%;
      height: 54px;
      line-height: 54px;
      float: left;
      margin: 1%;
      cursor: pointer;
      border-radius: 4px;
      &:hover,
      &.focused {
        background: $datepicker-hover-color;
      }
      &.disabled,
      &.disabled:hover {
        background: none;
        color: $btn-link-disabled-color;
        cursor: default;
      }
      &.active,
      &.active:hover,
      &.active.disabled,
      &.active.disabled:hover {
        @include button-variant($datepicker-active-color, $datepicker-active-color);
      }
      &.old,
      &.new {
        color: $btn-link-disabled-color;
      }
    }
  }

  .datepicker-switch {
    width: 145px;
  }

  .datepicker-switch,
  .prev,
  .next,
  tfoot tr th {
    cursor: pointer;
    &:hover {
      background: $datepicker-hover-color;
    }
  }

  .prev, .next {
    &.disabled {
      visibility: hidden;
    }
  }

  // Basic styling for calendar-week cells
  .cw {
    font-size: 10px;
    width: 12px;
    padding: 0 2px 0 5px;
    vertical-align: middle;
  }
}
.input-group.date .input-group-addon {
  cursor: pointer;
}
.input-daterange {
  width: 100%;
  
  .input-group-addon {
    width: auto;
    min-width: 16px;
    padding: 4px 5px;
    line-height: $line-height-base;
    border-width: 1px 0;
    margin-left: -5px;
    margin-right: -5px;
  }
}