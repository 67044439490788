//Custom variables

// Descriptive color variables

// Functional color variables
$color-payment: $aqua_marine;
$color-bonus: $turtle_green;
$color-penalty: $terra_cotta;

// Font stacks
$morouna-text-font: $font-family-sans-serif;
$morouna-header-font: 'Quicksand', sans-serif;

// Asset paths
// $path-image   :   '../img';
// $path-fonts     :  '../fonts';



$navbar-block-height: 54px;

$navbar-day-font-size: 30px;
$navbar-weekday-font-size: 15px;
$navbar-calendar-font-size: 11px;

$sidebar-width: 100px;
$sidebar-icon-size: 54px;
$sidebar-icon-circle-size: 54px;

$widget-spacing: $grid-gutter-width/3;
// Button
$btn-primary-color: $white;
// Table
$table-grey-color: #4a4a4a;


$circle-button-radius: 28px;