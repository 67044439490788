.jquery-guide,.jquery-guide-bg {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.jquery-guide-bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-color: rgba(255, 255, 255, 0.95);
  border-style: solid;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  z-index: 1090;
  div {
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
  }
  &.dark {
    div {
      box-shadow: 0 5px 35px 0 rgba(0, 0, 0, 0.08);
    }
  }
  &.light {
    div {
      box-shadow: 0 0 35px 0 rgba(255, 255, 255, 1);
    }
  }
}

.jquery-guide-dots {
  position: fixed;
  bottom: 25px;
  left: 0; right: 0;
  text-align: center;
  z-index: 1091;
  i,b {
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 1px solid $beige;
    border-radius: 16px;
    line-height: 16px;
    margin: 0 10px;
  }
  b {
    background: $beige;
  }
}

.jquery-guide-bg.active {
  border-color: rgba(0, 0, 0, 0.5);
}

.jquery-guide-content {
  position: absolute;
  cursor: default;
  color: #000;
  background: white;
  box-shadow: 0 0 35px 0 #fff, 0 0 15px 0 #fff;
  z-index: 1091;
  h1 {
    color: $aqua_marine;
    font-family: $morouna-header-font;
    font-weight: 400;
    font-size: 2.3rem;
    margin: 0 0 0.5rem 0;
    i {
      color: white;
      background: $beige;
      border-radius: 24px;
      line-height: 12px;
      font-size: 12px;
      padding: 3px 6px;
      margin-left: 10px;
      font-style: normal;
      position: relative;
      top: -16px;
    }
  }
  p {
    margin: 0.5rem 0;
  }
  a {
  }
  .form-inline-edit {
    display: inline-block;

  }
  .tag {
    color: $beige;
    border: 1px solid $beige;
    border-radius: 24px;
    line-height: 12px;
    font-size: 12px;
    padding: 6px 10px;
    margin-left: -10px;
  }
  .instruction {
    color: $beige;
    line-height: 12px;
    font-size: 12px;
  }
}

.guide-view-demo-toggle {
  li {
    list-style: none;
    padding: 0.4rem;
    &.active {
      @include shadow-panel-crisp()
    }
  }
}