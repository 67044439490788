form {
  label, .col-form-label {
    font-weight: $font-weight-light;
    &.required {
      font-weight: $font-weight-normal;
      &:after {
        font-weight: $font-weight-light;
        content: "*";
      }
    }
  }
  .form-control, .custom-select {
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  }
  .form-control-plaintext {
    font-weight: $font-weight-light;
  }

  .form-group-spacer {
    padding: $input-height-lg 0 0 0;
    margin: -$input-padding-y 0 0 0;
    text-align: center;
  }

  .form-group {
    &.inline-group {
      .input-group {
        box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);

        border: $input-border-width solid $input-border-color;
        @include border-radius($input-border-radius, 0);

        .form-control {
          box-shadow: none;
          background-color: transparent;
          border: none;
        }

        .input-group-prepend,
        .input-group-append {

        }

        .input-group-text {
          color: $beige_gray;
          background-color: transparent;
          border: 0;

          // Nuke default margins from checkboxes and radios to vertically center within.
          input[type="radio"],
          input[type="checkbox"] {
            margin-top: 0;
          }
        }
      }
    }
  }
}

.custom-select {
  text-transform: capitalize;
}
.custom-switch {
  .custom-control-label {
    &::before {
      border: none;
      box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.15), inset 0 2px 2px 0 rgba(0, 0, 0, 0.08);
    }
    &::after {
      box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.08), 0 0 3px 0 rgba(0, 0, 0, 0.15);
    }
  }
}

.btn {
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
}

.btn-primary {
  &.disabled, &:disabled {
    color: white;
  }
}

.btn-great {
  text-decoration: none;
  margin: 20px auto 0 auto;
  display: block;
}

.btn-primary, .btn-success, .btn-auth {
  color: $btn-primary-color;
}

.btn-outline-secondary {
  border-color: $beige_gray;
  color: $beige_gray;
  font-weight: $font-weight-normal;
  @include hover() {
    background-color: white;
    border-color: black;
    color: black;
  }
}

.btn-submit {
  @include circle-button();
  color: $btn-primary-color;
  background-color: $turtle_green;
}

.btn-selector {
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  height: $input-height;
  padding: $input-padding-y $input-padding-x;
  font-family: $input-font-family;
  @include font-size($input-font-size);
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;
  @include border-radius($input-border-radius, 0);
}

// inline mini-button for tables etc.
.form-inline-edit {
  margin: -4px 1em;

  &.form-inline-edit-left {
    left: 0;
    position: absolute;
  }
  &.form-inline-edit-right {
    right: 0;
    position: absolute;
  }
  &.form-inline-edit-middle {
    left: 0; right: 0;
    position: absolute;
  }

  vertical-align: middle;
  display: none;
}


// inline mini-button for tables etc.
.form-inline-edit-cell {
  margin: -$circle-button-radius/2 0 0 0;

  left: 0; right: 0;
  top: 50%; text-align: center;

  position: absolute;

  display: block;
  opacity: 0;

  margin-left: -$circle-button-radius/2;
  margin-right: -$circle-button-radius/2;

  will-change: transform,opacity;

  .left-button,
  .right-button {
    position: absolute;
    @include transition(all .3s ease-out);
  }

  .left-button {
    left: 0;
  }
  .right-button {
    right: 0;
  }
}

.form-inline-switch {
  display: inline-block;
}

.btn-icon,
a.btn-icon {
  color: $btn-primary-color;
  background-color: $aqua_marine;
  &:hover {
    color: $btn-primary-color;
    background-color: darken($aqua_marine, 5%);
  }

  text-decoration: none;
  font-weight: normal;

  i {
    display: inline-block;
    vertical-align: middle;
    width: 28px;
    height: 28px;
    margin: -14px 0;
    background-position: 50% 50%;
    background-size: 16px 16px;
    background-repeat: no-repeat;
  }

  &.btn-icon-download {
    padding-right: 42px;
    color: $btn-primary-color;
    &:hover {
      color: $btn-primary-color;
    }
    i {
      color: $btn-primary-color;
      background-image: url("/build/images/icons/mini-download.svg");
    }
  }
  &.btn-icon-view {
    padding-right: 42px;
    color: $btn-primary-color;
    &:hover {
      color: $btn-primary-color;
    }
    i {
      color: $btn-primary-color;
      background-image: url("/build/images/icons/mini-view.svg");
    }
  }
  &.btn-icon-account {
    padding-right: 42px;
    color: $btn-primary-color;
    &:hover {
      color: $btn-primary-color;
    }
    i {
      color: $btn-primary-color;
      background-image: url("/build/images/icons/mini-account.svg");
    }
  }
}

.btn-group {
  .btn {
    width: auto;
    text-decoration: none;

    font-weight: 300;
    font-family: $font-family-base;

    border: 1px solid #e1e6e9;

    height: $input-height;
    padding: $input-padding-y $input-padding-x;
    @include font-size($input-font-size);
    line-height: $input-line-height;
    @include border-radius($input-height);

    &:not(.active) {
      box-shadow: none;
    }

    &.active {
      background-color: #eff4f7;
      padding: $input-padding-y + 2 $input-padding-x $input-padding-y - 2 $input-padding-x;
    }
  }
}

.dropdown-menu {
  &.dropdown-menu-rounded {
    padding: 0;
    border-radius: 16px;
    box-shadow: 0 10px 35px 0 rgba(0,0,0,.08);
    border: 0;
    overflow: hidden;
    .dropdown-item {
      padding: 10px;
      color: $aqua_marine;
      text-decoration: underline;
      font-family: $font-family-sans-serif;
      &:active {
        background: $ice_blue;
      }
    }
  }
}

.form-inline-edit,
.form-inline-edit-cell,
.form-inline-panel,
.form-inline-edit-timeline,
.modal-dialog-inline {
  .btn:not(.btn-outline-secondary) {
    @include circle-button();
    border: none;

    &.btn-ok {
      color: $btn-primary-color;
      background-color: $turtle_green;
      background-image: url("/build/images/icons/mini-ok.svg");
      &:hover {
        background-color: darken($turtle_green, 5%);
      }
    }
    &.btn-ok-warning {
      color: $btn-primary-color;
      background-color: $terra_cotta;
      background-image: url("/build/images/icons/mini-ok.svg");
      &:hover {
        background-color: darken($terra_cotta, 5%);
      }
    }
    &.btn-add {
      color: $btn-primary-color;
      background-color: $turtle_green;
      background-image: url("/build/images/icons/mini-add.svg");
      &:hover {
        background-color: darken($turtle_green, 5%);
      }
    }
    &.btn-edit {
      color: $btn-primary-color;
      background-color: $turtle_green;
      background-image: url("/build/images/icons/mini-edit.svg");
      &:hover {
        background-color: darken($turtle_green, 5%);
      }
    }
    &.btn-password {
      color: $btn-primary-color;
      background-color: $turtle_green;
      background-image: url("/build/images/icons/mini-password.svg");
      &:hover {
        background-color: darken($turtle_green, 5%);
      }
    }
    &.btn-account {
      color: $btn-primary-color;
      background-color: $turtle_green;
      background-image: url("/build/images/icons/mini-account.svg");
      &:hover {
        background-color: darken($turtle_green, 5%);
      }
    }
    &.btn-settings {
      color: $btn-primary-color;
      background-color: $turtle_green;
      background-image: url("/build/images/icons/mini-settings.svg");
      &:hover {
        background-color: darken($turtle_green, 5%);
      }
    }
    &.btn-view {
      color: $btn-primary-color;
      background-color: $aqua_marine;
      background-image: url("/build/images/icons/mini-view.svg");
      &:hover {
        background-color: darken($aqua_marine, 5%);
      }
    }
    &.btn-download {
      color: $btn-primary-color;
      background-color: $aqua_marine;
      background-image: url("/build/images/icons/mini-download.svg");
      &:hover {
        background-color: darken($aqua_marine, 5%);
      }
    }
    &.btn-upload {
      color: $btn-primary-color;
      background-color: $turtle_green;
      background-image: url("/build/images/icons/mini-upload.svg");
      &:hover {
        background-color: darken($turtle_green, 5%);
      }
    }
    &.btn-refresh {
      color: $btn-primary-color;
      background-color: $terra_cotta;
      background-image: url("/build/images/icons/mini-refresh.svg");
      &:hover {
        background-color: darken($terra_cotta, 5%);
      }
    }
    &.btn-delete {
      color: $btn-primary-color;
      background-color: $terra_cotta;
      background-image: url("/build/images/icons/mini-delete.svg");
      &:hover {
        background-color: darken($terra_cotta, 5%);
      }
    }
    &.btn-destruct {
      color: $btn-primary-color;
      background-color: $terra_cotta;
      background-image: url("/build/images/icons/mini-stop.svg");
      &:hover {
        background-color: darken($terra_cotta, 5%);
      }
    }
  }
}

.form-inline-edit-timeline {
  .btn {

  }
}

.auth-form-row {
  margin-top: 10rem;
  padding-top: 35px;
  padding-bottom: 35px;
}

.auth-form {
  position: relative;
  background-color: white;
  padding: $grid-gutter-width 40px;

  @include shadow-panel();

  .auth-button-group {
    .btn {
      padding: 0.7rem 2.25rem;
    }
  }
  img {
    max-width: 271px;
    padding: 0;
  }
  p {
    font-size: 0.867rem;
  }
  .register-button-group {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    p {
      margin-right: 1rem;
      margin-bottom: 0;
    }
  }
  .restore-button-group {
    margin-top: 1.35rem;
  }
}

.login-form {
  .form-group {
    margin-bottom: 1.8rem;
  }
  .login-button-group {
    margin-top: 2.27rem;
  }
}

.password-showhide {
  position: relative;

  .password-showhide-button {
    position: absolute;
    top: 50%;
    right: $grid-gutter-width/3;
    font-size: 0.867rem;
    z-index: 1;
    color: $link-color;
    margin-top: -0.55rem;
    cursor: pointer;
    &:hover {
      color: $link-hover-color;
    }
  }
}

.input-iban-mask,
.input-government-id-mask {
  font-family: monospace;
}

.custom-file-label::after {
  font-size: 0.9375rem;
  color: $beige_gray;
  background-color: transparent;
}

.form-day-hours {
  margin: 0 0 0 $grid-gutter-width/8;
  &:first-child {
    margin: 0;
  }
}