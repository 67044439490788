i.ico {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin: -9px 0;
  background-position: 50% 50%;
  background-size: 18px 18px;
  background-repeat: no-repeat;
  vertical-align: super;

  &.help {
    background-image: url("/build/images/icons/icon-help.svg");
  }
  &.guide {
    background-image: url("/build/images/icons/icon-guide.svg");
  }
  &.info {
    background-image: url("/build/images/icons/icon-info.svg");
  }
  &.ok {
    background-image: url("/build/images/icons/icon-ok.svg");
  }
  &.warning {
    background-image: url("/build/images/icons/icon-warning.svg");
  }
}
.iconed-block {
  position: relative;
  i.ico {
    position: absolute;
    top: 10px;
    left: -24px;
  }
  a {
    color: $aqua_marine
  }
}
.guide-link {
  display: inline-block;
  width: 24px;
  height: 24px;
  font-size: 18px;
  line-height: 18px;
  transition: 0.3s all;
  &:hover {
    transform: scale(1.2, 1.2);
    transform-origin: 50% 50%;
  }

  i.ico.guide {
    width: 24px;
    height: 24px;
    margin: -12px 0;
    background-position: 50% 50%;
    background-size: 24px 24px;
  }
}
.guide-tip {
  display: inline-block;
  width: 18px;
  height: 18px;
  font-size: 18px;
  line-height: 18px;
}