.calendar {
  .calendar-header {
    border: 1px solid $beige;
    color: $beige;

    font-weight: 300;
    font-size: 30px;
    font-family: $headings-font-family;

    .year-title {
      font-weight: 300;
      font-size: 30px;
      width: 20%;
    }
    .year-neighbor {
      width: 18%;
    }
    .year-neighbor2 {
      width: 15%;
    }
  }

  .months-container {
    .month-container {
      height: 300px;
      .month {

      }
    }
  }
  table td,
  table th {
    width: 32px; height: 32px;
  }

  table.month {
    th.month-title {
      font-size: 15px;
      font-weight: 400;
      color: $beige-gray;
      a {
        color: $beige-gray;
        text-decoration: none;
      }
    }
    th.day-header {
      font-size: 15px;
      font-weight: 400;
      color: $beige;
    }
    td.day {
      .day-content {
        border-radius: 16px;
        padding: 6px 6px;
        &:hover {
          background: $ice_blue;
        }
      }

      &.range {
        .day-content {
          border-radius: 0;
          background: $ice_blue;
        }
        &.range-start {
          .day-content {
            border-top-left-radius: 16px;
            border-bottom-left-radius: 16px;
          }
        }
        &.range-end {
          .day-content {
            border-top-right-radius: 16px;
            border-bottom-right-radius: 16px;
          }
        }
      }
    }
  }
}