
$turtle_green:  #7dc242;
$aqua_marine:   #35bbdc;
$terra_cotta:   #da6e2b;
$ice_blue:      #eff4f7;
$beige:         #c9c6b8;
$beige_gray:    #9B9B9B;
$black:         #000;
$white:         #fff;

$light_ice_blue:#FAFCFD;

$primary:       $turtle_green;
$secondary:     $ice_blue;
$success:       $turtle_green;

$sidebar:       $aqua_marine;

$danger:        $terra_cotta;
$warning:       $terra_cotta;

$headings-font-family: 'Quicksand', sans-serif;

$body-color:  $black;

$grid-gutter-width: 47px;

$h1-font-size: 29px;
$h2-font-size: 29px;
$h3-font-size: 29px;

$modal-content-border-color:        transparent;
$modal-content-border-width:        0;
$modal-content-border-radius:       0;

// Color system

$theme-colors: (
        "auth":  $turtle_green
);
// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif:      "Roboto", "Tajawal", sans-serif; // "Tajawal" for arabic
// stylelint-enable value-keyword-case
//
//$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
//$font-size-lg:                $font-size-base * 1.25 !default;
//$font-size-sm:                $font-size-base * .875 !default;
//
//$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            700 !default;
//$font-weight-bolder:          bolder !default;
//
$font-weight-base:            $font-weight-light !default;
//$line-height-base:            1.5 !default;
//
//$h1-font-size:                $font-size-base * 2.5 !default;
//$h2-font-size:                $font-size-base * 2 !default;
//$h3-font-size:                $font-size-base * 1.75 !default;
//$h4-font-size:                $font-size-base * 1.5 !default;
//$h5-font-size:                $font-size-base * 1.25 !default;
//$h6-font-size:                $font-size-base !default;
//
$headings-margin-bottom:      1.34rem;
//$headings-font-family:        null !default;
//$headings-font-weight:        500 !default;
//$headings-line-height:        1.2 !default;
//$headings-color:              null !default;
//
//$display1-size:               6rem !default;
//$display2-size:               5.5rem !default;
//$display3-size:               4.5rem !default;
//$display4-size:               3.5rem !default;
//
//$display1-weight:             300 !default;
//$display2-weight:             300 !default;
//$display3-weight:             300 !default;
//$display4-weight:             300 !default;
//$display-line-height:         $headings-line-height !default;
//
//$lead-font-size:              $font-size-base * 1.25 !default;
//$lead-font-weight:            300 !default;
//
//$small-font-size:             80% !default;
//
//$text-muted:                  $gray-600 !default;
//
//$blockquote-small-color:      $gray-600 !default;
//$blockquote-small-font-size:  $small-font-size !default;
//$blockquote-font-size:        $font-size-base * 1.25 !default;
//
//$hr-border-color:             rgba($black, .1) !default;
//$hr-border-width:             $border-width !default;
//
//$mark-padding:                .2em !default;
//
//$dt-font-weight:              $font-weight-bold !default;
//
//$kbd-box-shadow:              inset 0 -.1rem 0 rgba($black, .25) !default;
//$nested-kbd-font-weight:      $font-weight-bold !default;
//
//$list-inline-padding:         .5rem !default;
//
//$mark-bg:                     #fcf8e3 !default;
//
//$hr-margin-y:                 $spacer !default;

// Links
//
// Style anchor elements.

$link-decoration: underline;

$enable-validation-icons: false;

// Components
//
// Define common padding and border radius sizes and more.

$border-radius: 3px;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: 9px;
$input-btn-padding-x: 15px;

$btn-padding-y: 14px;
$btn-padding-x: 20px;

// Forms
//
$label-margin-bottom: 2px;
$input-font-size: 15px;
$input-font-weight: $font-weight-normal;
$input-line-height: 1.1;
$custom-select-indicator-padding: 15px;

$enable-shadows: true;

$input-color: $black;
$input-border-color: #979797;
$input-box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.2);

$custom-control-indicator-size: 1.625rem;
$custom-switch-width: $custom-control-indicator-size * 1.9235;
$custom-control-indicator-border-color: $white;

$form-grid-gutter-width: 15px;
// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-x:               30px;
$btn-font-size:               15px;
$btn-font-weight:             $font-weight-bold;
$btn-box-shadow:              0 5px 25px 0 rgba(0, 0, 0, 0.1);
$btn-line-height:             1;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: 0px;

// Tables
$table-bg:                    rgba($ice_blue, .3);
$table-accent-bg:             $ice_blue;

// Progress bars

$progress-bg:                       transparent;
$progress-border-radius:            0;
$progress-bar-bg:                   $beige;

$tooltip-arrow-color: $ice_blue;
$tooltip-bg: $ice_blue;
$tooltip-color: #000;
$tooltip-font-size: 80%;
$tooltip-opacity: 1;