.gantt-schedule-timeline-calendar__chart-timeline-items-row-item {
  border-radius: 13px;

  padding: 0;
  height: 28px;
  margin-top: calc((var(--row-height) - 28px)/2);
  line-height: 26px;

  &.payments-summary {

    .gantt-schedule-timeline-calendar__chart-timeline-items-row-item-label {
      text-align: center;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      a {
        padding: 0;
      }
    }

    padding: 0;
    height: 50px;
    margin-top: calc((var(--row-height) - 50px)/2);
    line-height: 14px;
    font-size: 14px;
    background: transparent;
    border-radius: 0;
    color: black;

    span {
      display: block;

      opacity: 0.3;

      &.no-contracts {
        opacity: 1;
      }

      &[data-type="penalty"] {
        color: $terra_cotta;
      }
      &[data-type="salary"] {
        color: $aqua_marine;
      }
      &[data-type="bonus"] {
        color: $turtle_green;
      }
    }
  }

  &[data-type="leave_day"] {
    background-color: $beige-gray;
  }
  &[data-type="sick_day"] {
    background-color: $terra_cotta;
  }
  &[data-type="vacation"] {
    background-color: $aqua_marine;
  }
  &[data-type="holiday"] {
    background-color: $turtle_green;
  }

  &[data-type="penalty"] {
    background-color: $terra_cotta;
  }
  &[data-type="salary"] {
    background-color: $aqua_marine;
  }
  &[data-type="bonus"] {
    background-color: $turtle_green;
  }

  a {
    padding: 0 0 0 3px;
    color: white;
    text-decoration: none;
    font-size: 11px;
  }
}

.gantt-schedule-timeline-calendar__chart-timeline-grid-row-block {
  .form-inline-edit-timeline {
    margin-top: 12px;
    display: none;
  }
  &:hover,
  &.hover {
    .form-inline-edit-timeline {
      display: block;
    }
  }

  &.current {
    background: white;
    border-left: 1px solid $beige;
    border-right: 1px solid $beige;
  }
}

.gantt-schedule-timeline-calendar__chart-calendar {
  background: #fff;
}

.gantt-schedule-timeline-calendar__chart-calendar-date {
  border-right: 1px solid transparent;
}

.gantt-schedule-timeline-calendar__chart-calendar-date--month {
  border-right: 1px solid black;
}

.gantt-schedule-timeline-calendar__chart-calendar-date--day {
  &.gstc-current {
    box-shadow: none;
    border-right: 1px solid $beige;
    border-left: 1px solid $beige;
    border-top: 1px solid $beige;
  }
}
.gantt-schedule-timeline-calendar__chart-calendar-date-content {
  &.gstc-date-top {

  }
  &.gstc-date-small {
    font-size: 11px;
  }
}
.gantt-schedule-timeline-calendar__list-column-header {
  background: #fff;
  border-right: 1px solid black;
}
