body {
	&::-webkit-scrollbar-track
	{
		background-color: white;
	}

	&::-webkit-scrollbar
	{
		width: 8px; height: 8px;
		background-color: white;
	}

	&::-webkit-scrollbar-thumb
	{
		border: 2px solid white;
		background-clip: padding-box;
		border-radius: 8px;
		background-color: $ice_blue;
		box-shadow: inset 0 6px 10px rgba(0,0,0,0.08), inset 0 0 3px rgba(0,0,0,0.15);
	}
}

#page_container {
	padding: 120px $grid-gutter-width/2 0 ($grid-gutter-width/2 + $sidebar-width);
	/*max-width: 1280px + $sidebar-width;*/

	max-width: 97%;

	&.blurred {
		animation-name: blurred;
		animation-duration: 0.3s;
		filter: blur(8px)
	}
	&.unblurred {
		animation-name: unblurred;
		animation-duration: 0.3s;
		filter: blur(0px)
	}
}

.scalable-container {
	width: 1280px + $sidebar-width;
	transform-origin: $sidebar-width 0;
}

@keyframes blurred {
	0% { filter: blur(0px) }
	100% { filter: blur(8px) }
}
@keyframes unblurred {
	0% { filter: blur(8px) }
	100% { filter: blur(0px) }
}



.grid-container {
	width: 100%;
	max-width: 1280px;
	margin: auto;
	padding: 0 15px;
	align-items: center;
	display: grid;
	grid-template-columns: repeat(14, 1fr);
	grid-column-gap: 40px;
	.main-item {
		grid-column: 1/15;
	}

	h1 {
		.code {
			font-weight: bold;
			color: $beige;
			display: block;
			font-size: 4rem;
		}
	}
}

.wrapper-fluid {
	position: relative;
	display: flex;
	justify-content: flex-end;
	flex-flow: row wrap;
	overflow: hidden;
	min-height: 100%;
}
.wrapper-small {
	width: 100%;
	z-index: 1;
	.bg-img {
		position: absolute;
		top: -55px;
		left: 50%;
		width: 1440px;
		z-index: -1;
		margin-left: -111px;
	}
}
.wrapper-nav {
	margin-top: 7vh;
	margin-bottom: 5vh;
}
.bg-bottom {
	width: 100%;
	min-height: 100px;
	background-image: linear-gradient(to bottom, #ffffff, $ice_blue);
	z-index: -1;
}