// fixed header

table.dataTable.fixedHeader-floating,
table.dataTable.fixedHeader-locked {
	background-color: white;
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}

table.dataTable.fixedHeader-floating {
	position: fixed !important;
	@include shadow-panel-crisp();
	z-index: $datatables-fixed-header-z-index;
}

table.dataTable.fixedHeader-locked {
	position: absolute !important;
}

@media print {
	table.fixedHeader-floating {
		display: none;
	}
}


// fixed columns

table.DTFC_Cloned thead,
table.DTFC_Cloned tfoot {
	background-color: white;
}

div.DTFC_Blocker {
	background-color: white;
}

div.DTFC_LeftWrapper table.dataTable,
div.DTFC_RightWrapper table.dataTable {
	margin-bottom: 0;
	z-index: 2;
}
div.DTFC_LeftWrapper table.dataTable.no-footer,
div.DTFC_RightWrapper table.dataTable.no-footer {
	border-bottom: none;
}



// scroller

div.dts {
	display: block !important;
}
div.dts tbody th,
div.dts tbody td {
	white-space: nowrap;
}
div.dts div.dts_loading {
	z-index: 1;
}
div.dts div.dts_label {
	position: absolute;
	visibility: hidden; // hide for now
	background: rgba(0, 0, 0, 0.8);
	color: white;
	box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5);
	text-align: right;
	border-radius: 3px;
	padding: 0.4em;
	z-index: 2;
	display: none;
}
div.dts div.dataTables_scrollBody {
	background: repeating-linear-gradient(45deg, $ice_blue, $ice_blue 10px, white 10px, white 20px);



	&::-webkit-scrollbar-track
	{
		background-color: white;
	}

	&::-webkit-scrollbar
	{
		width: 3px; height: 3px;
		background-color: white;
	}

	&::-webkit-scrollbar-thumb
	{
		border-radius: 3px;
		background-color: $ice_blue;
	}
}
div.dts div.dataTables_scrollBody table {
	z-index: 2;
}
div.dts div.dataTables_paginate,
div.dts div.dataTables_length {
	display: none;
}

div.DTS div.dataTables_scrollBody table {
	background-color: white;
}

.formatter-name {

}
.formatter-period {
	font-size: 14px;
	opacity: 0.8;
}
.formatter-department {
	opacity: 0.5;
	float: right;
}
.formatter-department-small {
	display: block;
	color: $beige-gray;
	font-size: 12px;
}
.formatter-contract-small {
	display: block;
	color: $aqua_marine;
	font-size: 12px;
}
.formatter-status {

}
.formatter-status-new {
	color: $turtle_green;
}
.formatter-status-sent {
	color: $aqua_marine;
}
.formatter-status-hold {
	color: $terra_cotta;
}
.formatter-status-complete {
	color: $beige;
}
.formatter-datetime-small {
	font-size: 10px;
	float: left;
	line-height: 1;
}
.formatter-datetime {
	font-size: 14px;
}
.formatter-datetime-fromnow {
	line-height: 1;
	float: right;
	font-size: 11px;
	opacity: 0.6;
	font-weight: 300 !important;
}
.formatter-auto {
	color: $aqua_marine;
	line-height: 1.3rem;
}
.formatter-amount {
	font-size: 1.3rem;
	line-height: 1;
	float: right;
}
.formatter-amount-small {
	font-size: 0.9rem;
}
.formatter-rate,
.formatter-basic {
	color: $beige-gray;
}
.formatter-monthly {
	float: left;
	color: $beige;
}
.formatter-salary {
	color: $aqua_marine;
	&.formatter-amount:before {
		content: "\002B"
	}
}
.formatter-bonus {
	color: $turtle_green;
	&.formatter-amount:before {
		content: "\002B"
	}
}
.formatter-penalty {
	color: $terra_cotta;
	&.formatter-amount:before {
		content: "\2212"
	}
}
.formatter-type {
	font-size: 1rem;
	line-height: 1;
	text-transform: capitalize;
}
.formatter-meaning {
	float: right;
	font-size: 0.8rem;
	color: $beige_gray;
	padding: 0 5px;
	margin: 0 -5px 0 15px;
	background: white;
}
.formatter-note {
	float: right;
	font-size: 0.8rem;
	color: black;
	margin: 0 0 0 15px;
}
.formatter-day-type {
	font-size: 0.8rem;
	line-height: 1.5;
	color: white;
	font-weight: 300;
	position: absolute;
	left: 0; right: 0;
	text-align: center;
	height: 25%;
	a {
		color: white;
		text-decoration: none;
	}
	&.formatter-add-wrapper {
		display: none;
	}
}

td:hover .formatter-add-wrapper {
	display: block;
}

.formatter-day-type-leave_day {
	top: 0;
	background-color: $beige-gray;
}
.formatter-day-type-sick_day {
	top: 25%;
	background-color: $terra_cotta;
}
.formatter-day-type-vacation {
	top: 50%;
	background-color: $aqua_marine;
}
.formatter-day-type-holiday {
	top: 75%;
	background-color: $turtle_green;
}



.tooltip-type-leave_day {
	color: $beige-gray;
}
.tooltip-type-sick_day {
	color: $terra_cotta;
}
.tooltip-type-vacation {
	color: $aqua_marine;
}
.tooltip-type-holiday {
	color: $turtle_green;
}


.tooltip-holder {
	position:absolute;
	left:0; right:0; top:0; bottom:0;
}

.inline-add-wrapper {
	white-space: nowrap;

	a.inline-add {
		padding: 6px;
		border-radius: 17px;
		height: 28px;
		color: white;
		text-decoration: none;
		display: inline-block;
		font-size: 0.9rem;
		line-height: 1.2;

		&.salary {
			background-color: $aqua_marine;
		}
		&.bonus {
			background-color: $turtle_green;
		}
		&.penalty {
			background-color: $terra_cotta;
		}

		&.leave_day {
			background-color: $beige-gray;
		}
		&.sick_day {
			background-color: $terra_cotta;
		}
		&.vacation {
			background-color: $aqua_marine;
		}
		&.holiday {
			background-color: $turtle_green;
		}
	}
}