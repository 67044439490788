/*
Color Scheme: http://paletton.com/palette.php?uid=c1T3n2J040kpEKzpEKzbEPSOEyiNk9W
*/

[ui-pnotify] {
  &.ui-pnotify .brighttheme {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    &.ui-pnotify-container {
      padding: 1rem;
    }
  }
  &.ui-pnotify-with-icon .brighttheme {
    .ui-pnotify-title, .ui-pnotify-text, .ui-pnotify-confirm {
      margin-left: 2rem;
      margin-right: 2rem;
    }
  }
}

[dir=rtl] [ui-pnotify].ui-pnotify-with-icon .brighttheme {
  .ui-pnotify-title, .ui-pnotify-text, .ui-pnotify-confirm {
    margin-right: 2rem;
    margin-left: 2rem
  }
}

[ui-pnotify].ui-pnotify {
  .brighttheme {
    &.ui-pnotify-shadow {
      @include shadow-panel();
    }

    position: relative;
    border-radius: $navbar-block-height/2;

    .ui-pnotify-title {
      font-size: 1.2rem;
      line-height: 1.4rem;
      margin-top: -.2rem;
      margin-bottom: 1rem;
    }
    .ui-pnotify-text {
      font-family: $morouna-header-font;
      font-weight: 400;
      font-size: 1.1rem;
      line-height: 1.4rem;
      margin-top: 0;
      text-align: center;
    }
    .ui-pnotify-icon {
      line-height: 1;
    }
  }
  .brighttheme-notice {
    background-color: $ice_blue;
    border: 0 solid $ice_blue;
    h4, div {
      color: darken($ice_blue, 50%);
    }
  }
  .brighttheme-info {
    background-color: $aqua_marine;
    border: 0 solid $aqua_marine;
    h4, div {
      color: white
    }
  }
  .brighttheme-success {
    background-color: $turtle_green;
    border: 0 solid $turtle_green;
    h4, div {
      color: white;
    }
  }
  .brighttheme-error {
    background-color: $terra_cotta;
    background-image: none;
    border: 0 solid $terra_cotta;
    h4, div {
      color: white;
    }
  }
  .brighttheme {
    .ui-pnotify-closer, .ui-pnotify-sticker {
      font-size: 1rem;
      line-height: 1.2rem;
    }
  }
  .brighttheme-icon-notice, .brighttheme-icon-info, .brighttheme-icon-success, .brighttheme-icon-error, .brighttheme-icon-closer, .brighttheme-icon-sticker {
    position: absolute;
    width: 28px;
    height: 28px;
    font-size: 0;
    font-weight: bold;
    line-height: 1;
    font-family: "Courier New",Courier,monospace;
    border-radius: 50%;
    top: 13px; right: 13px;
  }
  .brighttheme-icon-notice:after, .brighttheme-icon-info:after, .brighttheme-icon-success:after, .brighttheme-icon-closer:after, .brighttheme-icon-sticker:after {
    position: absolute;
    top: 0;
    left: .2rem;
  }
  .brighttheme-icon-notice {
    background: url("/build/images/icons/flash-notice.svg");
    color: white;
    &:after {
      content: "";
    }
  }
  .brighttheme-icon-info {
    background: url("/build/images/icons/flash-info.svg");
    color: white;
    &:after {
      content: "i";
    }
  }
  .brighttheme-icon-success {
    background: url("/build/images/icons/flash-success.svg");
    color: white;
    &:after {
      content: "";
    }
  }
  .brighttheme-icon-error {
    background: url("/build/images/icons/flash-error.svg");
    color: white;
    &:after {
      content: "";
    }
  }
  .brighttheme-icon-closer, .brighttheme-icon-sticker {
    display: inline-block;
  }
  .brighttheme-icon-closer:after {
    content: "\002715";
  }
  .brighttheme-icon-sticker {
    &:after {
      top: -1px;
      content: "\002016";
    }
    &.brighttheme-icon-stuck:after {
      content: "\00003E";
    }
  }
  .brighttheme {
    .ui-pnotify-confirm {
      margin-top: 1rem;
    }
    .ui-pnotify-prompt-bar {
      margin-bottom: 1rem;
    }
    .ui-pnotify-action-button {
      text-transform: uppercase;
      font-weight: bold;
      padding: .4rem 1rem;
      border: none;
      background: transparent;
      cursor: pointer;
    }
  }
  .brighttheme-notice .ui-pnotify-action-button.brighttheme-primary {
    background-color: #FFFF00;
    color: #4F4F00;
  }
  .brighttheme-info .ui-pnotify-action-button.brighttheme-primary {
    background-color: #0286A5;
    color: #012831;
  }
  .brighttheme-success .ui-pnotify-action-button.brighttheme-primary {
    background-color: #35DB00;
    color: #104300;
  }
  .brighttheme-error .ui-pnotify-action-button.brighttheme-primary {
    background-color: #FF1800;
    color: #4F0800;
  }
}