#navbar_left {
	backdrop-filter: blur(15px);
	background: white;

	z-index: $zindex-modal+1;

	bottom: 0;
	left: 0;
	top: 0;
	width: $sidebar-width;
	overflow: auto;

	&::-webkit-scrollbar-track
	{
		background-color: white;
	}

	&::-webkit-scrollbar
	{
		width: 3px; height: 3px;
		background-color: white;
	}

	&::-webkit-scrollbar-thumb
	{
		border-radius: 3px;
		background-color: $ice_blue;
	}

	@include transition(all 0.1s ease-in-out);

	align-items: flex-start;
	padding: 2rem 0;

	@include shadow-panel();

	.navbar-nav {
		.nav-item {
			width: 100%;
			position: relative;

			.nav-day {
				position: absolute;
				top: 17px;
				right: 14px;
				width: 26px;
				text-align: center;
				font-weight: bold;
				font-size: 17px;
				font-family: $morouna-text-font;
				color: white;
			}

			.nav-icon {
				display: inline-block;
				line-height: 1; font-size: 1px;
				border-radius: $sidebar-icon-circle-size/2;
				padding: ($sidebar-icon-circle-size - $sidebar-icon-size)/2;
				background: $sidebar;
				img {
					width: $sidebar-icon-size;
					height: $sidebar-icon-size;
				}
				&.nav-icon-normal {
					display: inline-block;
				}
				&.nav-icon-active {
					display: none;
				}
			}

			&.current {
				.nav-icon {
					&.nav-icon-normal {
						display: none;
					}
					&.nav-icon-active {
						display: inline-block;
					}
				}
			}

			.nav-label {
				position: absolute;
				left: -$sidebar-width*5;
				font-size: 15px;
				line-height: 1;
				top: $sidebar-icon-circle-size/2 - 7.5px;
			}
		}

		.nav-link {
			font-weight: 300;
			white-space: nowrap;
			display: inline-block;
			width: $sidebar-width - ($sidebar-width - $sidebar-icon-circle-size)/2;
			padding: 0;
			margin: 0 0 1.5rem 0;

			text-align: right;

			border-top-right-radius: $sidebar-icon-circle-size/2;
			border-bottom-right-radius: $sidebar-icon-circle-size/2;

			color: $sidebar;

			overflow: hidden;

			@include hover-focus {
				color: darken($sidebar, 10%);
			}

			&.disabled {
				.nav-icon {
					background: $ice_blue;
				}
				color: lighten($sidebar, 50%);
			}

			&.active {
				background: $sidebar;
			}
		}

		.show > .nav-link,
		.active > .nav-link,
		.nav-link.show,
		.nav-link.active {
			color: $navbar-light-active-color;
		}


		&.navbar-nav-userpanel {

			.nav-link {
				text-decoration: underline;
			}
		}
		&.navbar-nav-calendar {
			text-decoration: none;
			.nav-link {

				font-size: $navbar-weekday-font-size;
				line-height: 1;
				padding-top: ($navbar-block-height - $navbar-calendar-font-size - $navbar-weekday-font-size) / 2;
				padding-bottom: ($navbar-block-height - $navbar-calendar-font-size - $navbar-weekday-font-size) / 2;

				text-align: right;

				vertical-align: middle;

				color: $beige;

				@include hover-focus {
					color: darken($beige, 10%);
				}

				&.disabled {
					color: lighten($beige, 50%);
				}

				small {
					font-size: $navbar-calendar-font-size;
					display: block;
				}
			}

			.navbar-item-icon {
				color: $beige_gray;
				padding-top: ($navbar-block-height - $navbar-day-font-size) / 2;;
				line-height: 1;
				font-family: $morouna-header-font;
				text-align: center;
				font-size: $navbar-day-font-size;
			}
		}
	}

	&.navbar-expand {
		width: $sidebar-width*2.2;

		.navbar-nav {
			.nav-item {
				.nav-label {
					left: $sidebar-width*0.9;
				}
			}
		}
	}
}

.sidepanel-icon {
}