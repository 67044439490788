/* Custom Mixins -------------------- */

@mixin shadow-panel() {
  box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.08);
}

@mixin shadow-panel-crisp() {
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15), 0 6px 10px 0 rgba(0, 0, 0, 0.15);
}

@mixin shadow-panel-crisp-after() {
  box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.15), inset 0 6px 10px 0 rgba(0, 0, 0, 0.15);
}

@mixin circle-button() {
  padding: 6px;
  border-radius: $circle-button-radius / 2;
  width: $circle-button-radius;
  height: $circle-button-radius;
  background-position: 50% 50%;
  background-size: 16px 16px;
  background-repeat: no-repeat;

  img {
    width: 16px;
    height: 16px;
  }
}