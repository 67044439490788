/* Base Styles -------------------- */
@import url('https://fonts.googleapis.com/css?family=Quicksand:300,500|Roboto:300,400,500,700|Tajawal:300,400,500,700'); // Tajawal for arabic

html {
	font-size: 15px;
}

html, body {
	height: 100%;
}

h1 {
	font-weight: 500;
}
h2 {
	font-weight: 500;
}
h3 {
	font-weight: lighter;
}

.text-payment {
	color: $color-payment;
}
.text-bonus {
	color: $color-bonus;
}
.text-penalty {
	color: $color-penalty;
}
.text-opacity {
	opacity: 0.4;
}
.font-weight-medium {
	font-weight: 500;
}
.table-transparent {
	background-color: transparent !important;
}
small {
	font-weight: $font-weight-light;
}


@-webkit-keyframes autofill {
	0%,100% {
		color: #666;
		background-color: white !important;
		font-family: $font-family-sans-serif;
		font-size: $input-font-size;
		font-weight: $input-font-weight;
	}
}

input:-webkit-autofill {
	animation-delay: 1s; /* Safari support - any positive time runs instantly */
	animation-name: autofill;
	animation-fill-mode: both;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
	-webkit-text-fill-color: black;
	-webkit-box-shadow: 0 0 0px 1000px #fff inset;
	transition: background-color 5000s ease-in-out 0s;
}