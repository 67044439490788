.logo-group {
	.stlogo {
		fill: none;
		stroke: #eff4f7;
		stroke-width: 5px;
		stroke-linecap: round;
		stroke-miterlimit: 10;
	}
	.logo-loading-animated {
		stroke-dasharray: 10;
		stroke-dashoffset: 0;
		animation: dashes 1.5s linear infinite;
	}
	@keyframes dashes {
		from {
			stroke-dashoffset: 100;
		}
		to {
			stroke-dashoffset: 0;
		}
	}
	&.logo-group-widget {
		position: absolute;
		top: 50%; left: 50%;
		.stlogo {
			stroke-width: 2.5px;
		}
		svg {
			margin-top: -50%;
			margin-left: -40px;
			transform: scale(3,3);
		}
	}
}

#welcome_header {
	position: absolute;
	top: 0; left: 0; right: 0;
	padding-top: 85px;
	padding-bottom: 45px;
	z-index: 2;

	.logo-group {
		grid-column: 5/11;
		padding: 10px 0;
		text-align: center;
		white-space: nowrap;
	}

	.nav {
		grid-column: 11/15;

		.nav-item {
			margin-right: 3rem;
			.nav-link {
				text-decoration: none;
				white-space: nowrap;
				background-color: $aqua_marine;
				color: white;
				padding: 8px 38px;
				border-radius: 27px;
				box-shadow: 0 10px 35px 0 rgba(0,0,0,.08);
				font-weight: 400;
				&:hover {
					background-color: darken($aqua_marine, 5%);
				}
			}
		}
	}
}

#navbar_top {
	margin: 0 auto;
	padding: 2rem $grid-gutter-width/2 2rem ($grid-gutter-width/2 + $sidebar-width);
	/*max-width: 1280px + $sidebar-width;*/
	max-width: 98%;
	justify-content: space-between;

	background: white;

	z-index: $zindex-modal-backdrop+1;

	.navbar-brand {
		@include transition(all 0.3s ease);
		margin-top: 0;
	}

	&.shrinked {
		.navbar-brand {
			margin-top: -200px;
		}

		.navbar-block-calendar {

		}
	}


	.navbar-brand {
		color: $navbar-light-brand-color;

		@include hover-focus {
			color: $navbar-light-brand-hover-color;
		}
	}

	.navbar-nav {
		@include shadow-panel();

		background: white;

		margin-left: $grid-gutter-width;
		white-space: nowrap;
		height: $navbar-block-height; border-radius: $navbar-block-height/2;
		padding-right: $navbar-block-height/4;

		.navbar-item-icon {
			font-weight: $font-weight-normal;

			display: inline-block; vertical-align: middle;
			margin: 0;

			background-color: lighten($beige, 50%);

			width: $navbar-block-height;
			height: $navbar-block-height;
			border-radius: $navbar-block-height/2;
			overflow: hidden;
		}

		.nav-item {
			font-size: 15px;
			line-height: $navbar-block-height - 15px;
		}

		.nav-link {
			font-weight: $font-weight-normal;

			display: inline-block;

			color: $aqua_marine;

			overflow: hidden;

			@include hover-focus {
				color: darken($aqua_marine, 10%);
			}

			&.disabled {
				color: lighten($aqua_marine, 50%);
			}
		}

		.show > .nav-link,
		.active > .nav-link,
		.nav-link.show,
		.nav-link.active {
			color: $navbar-light-active-color;
		}

		&.navbar-nav-business {
			text-decoration: none;

			.nav-link {
				text-decoration: none;

				font-size: $navbar-weekday-font-size;
				line-height: 1;
				padding-top: ($navbar-block-height - $navbar-calendar-font-size - $navbar-weekday-font-size) / 2;
				padding-bottom: ($navbar-block-height - $navbar-calendar-font-size - $navbar-weekday-font-size) / 2;

				text-align: right;

				vertical-align: middle;

				color: $beige_gray;

				@include hover-focus {
					color: darken($beige_gray, 10%);
				}

				&.disabled {
					color: lighten($beige_gray, 50%);
				}

				small {
					font-size: $navbar-calendar-font-size;
					display: block;
				}
			}

			.navbar-item-currency {
				color: $beige_gray;
				padding-top: ($navbar-block-height - $navbar-day-font-size) / 2;
				line-height: 1;
				font-family: $morouna-header-font;
				text-align: center;
				font-size: $navbar-day-font-size;

				width: $navbar-block-height;
				height: $navbar-block-height;
				border-radius: $navbar-block-height/2;
			}
		}

		&.navbar-nav-calendar {
			text-decoration: none;
			.nav-link {
				text-decoration: none;

				font-size: $navbar-weekday-font-size;
				line-height: 1;
				padding-top: ($navbar-block-height - $navbar-calendar-font-size - $navbar-weekday-font-size) / 2;
				padding-bottom: ($navbar-block-height - $navbar-calendar-font-size - $navbar-weekday-font-size) / 2;

				text-align: right;

				vertical-align: middle;

				color: $beige_gray;

				@include hover-focus {
					color: darken($beige_gray, 10%);
				}

				&.disabled {
					color: lighten($beige_gray, 50%);
				}

				small {
					font-size: $navbar-calendar-font-size;
					display: block;
				}
			}

			.navbar-item-icon {
				color: $beige_gray;
				padding-top: ($navbar-block-height - $navbar-day-font-size) / 2;;
				line-height: 1;
				font-family: $morouna-header-font;
				text-align: center;
				font-size: $navbar-day-font-size;
				border: 1px solid $beige;
			}
		}

		&.navbar-nav-userpanel {
			position: relative;
			.labels {
				position: absolute;
				top: -1em;
				left: $sidebar-icon-circle-size*2/3;
				font-size: 80%;
				border-radius: 1em;
				background: white;
				overflow: hidden;
				.subscription-plan-label {
					display: inline-block;
					color: $beige_gray;
					padding: 0.25em 0.75em;
					font-weight: bold;
					font-family: $headings-font-family;
					border-radius: 1em;
					border: 1px solid $beige_gray;
				}
				.expires-label, .expired-label {
					display: inline-block;
					padding: 0.25em 0.75em;
				}
				.expired-label {
					a {
						color: $terra_cotta;
					}
				}
			}
			.navbar-item-icon {
				background: $aqua_marine;
				.userpic {
					img {
						width: $navbar-block-height - 10px;
						height: $navbar-block-height - 10px;
						margin: 10px 0 0 5px;
					}

				}
			}
		}
	}

	.navbar-toggler {
		color: $navbar-light-color;
		border-color: $navbar-light-toggler-border-color;
	}

	.navbar-toggler-icon {
		background-image: $navbar-light-toggler-icon-bg;
	}

	.navbar-text {
		color: $navbar-light-color;
		a {
			color: $navbar-light-active-color;

			@include hover-focus {
				color: $navbar-light-active-color;
			}
		}
	}
}